body,
html {
    font-family: Light
}

@font-face {
    font-family: "Light";
    src: local("SVN-Internacional"),
        url("/src/fonts/SVN-Internacional-Light.otf") format("truetype");
}

@font-face {
    font-family: "Bold";
    src: local("SVN-Internacional"),
        url("/src/fonts/SVN-Internacional-Bold.otf") format("truetype");
}

section {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    height: auto;
}

section iframe,
section object,
section embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}